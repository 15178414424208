<template>
  <div>
    <a-card :bordered="false">
      <a-page-header
        style="border: 1px solid rgb(235, 237, 240); margin-bottom: 35px"
        title="关联商品"
        sub-title=""
        @back="() => $router.go(-1)"
      >
      </a-page-header>
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="5" :sm="24">
              <div class="table-operator">
                <a-button type="primary" icon="plus" @click="handleCreate">添加</a-button>
              </div>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <s-table
        ref="table"
        size="small"
        rowKey="id"
        :loading="loading"
        :columns="columns"
        :data="loadData"
      >
        <span slot="serial" slot-scope="text, record, index">
          {{ index + 1 }}
        </span>
        <div slot="is_enable" slot-scope="text, record">
          <a-switch @change="handleUsed(record)" :checked="text"/>
        </div>
        <span slot="action" slot-scope="text, record">
          <template>
            <a-popconfirm
              title="确定要删除么？"
              ok-text="确定"
              cancel-text="取消"
              @confirm="handleDelete(record)"
            >
              <a>删除</a>
            </a-popconfirm>
          </template>
        </span>
      </s-table>
    </a-card>
    <create-coupon-goods-form
      ref="createModal"
      v-if="visible"
      :visible.sync="visible"
      :loading="confirmLoading"
      :model="mdl"
      @cancel="handleAddCancel"
      @ok="handleOk"
    />
  </div>
</template>

<script>
import { STable } from '@/components'
import { couponGoodsCreate, putCoupon, couponGoodsList, couponGoodsDelete } from '@/api/applet_shop_coupon'
import CreateCouponGoodsForm from '@/views/a-applet/shop_manage/coupon/module/CreateCouponGoodsForm'

export default {
  name: 'TableList',
  components: {
    STable,
    CreateCouponGoodsForm
  },
  data () {
    return {
      loading: false,
      visible: false,
      confirmLoading: false,
      mdl: {},
      // 查询参数
      queryParam: {},
      // 表头
      columns: [
        {
          title: '序号',
          width: '50px',
          scopedSlots: { customRender: 'serial' }
        },
        {
          title: '名称',
          ellipsis: true,
          dataIndex: 'name'
        },
        {
          title: '条形码',
          ellipsis: true,
          dataIndex: 'barcode'
        },
        {
          title: '操作',
          dataIndex: 'action',
          width: '150px',
          scopedSlots: { customRender: 'action' }
        }
      ],
      // 加载数据方法 必须为 Promise 对象
      loadData: parameter => {
        return couponGoodsList(Object.assign(parameter, this.queryParam))
          .then(({ data }) => {
            return { entries: data }
          })
      }
    }
  },
  created () {
    this.queryParam.coupon_id = this.$route.query.id
  },
  methods: {
    handleCreate () {
      this.mdl = null
      this.visible = true
      this.confirmLoading = false
      // this.$router.push({ name: 'CouponCreate' })
    },
    handleAddCancel () {
      this.visible = false
      const form = this.$refs.createModal.form
      form.resetFields() // 清理表单数据（可不做）
    },
    handleOk () {
      const form = this.$refs.createModal.form
      form.validateFields((errors, values) => {
        if (!errors) {
          this.confirmLoading = true
          if (this.mdl && this.mdl.id) {
            putCoupon(values, this.mdl.id).then((res) => {
              if (res.code === 1000) {
                this.visible = false
                this.$refs.table.refresh()
              }
              this.confirmLoading = false
            }).finally(() => {
              this.confirmLoading = false
            })
          } else {
            values.coupon_id = this.$route.query.id
            console.log('提交的数据----', values)
            couponGoodsCreate(values).then((res) => {
              if (res.code === 1000) {
                this.visible = false
                this.$refs.table.refresh()
              }
              this.confirmLoading = false
            }).finally(() => {
              this.confirmLoading = false
            })
          }
        } else {
          this.confirmLoading = false
        }
      })
    },
    handleUsed (item) {
      console.log(item)
      this.loading = true
      putCoupon({ is_enable: !item.is_enable }, item.id).then((res) => {
        if (res.code === 1000) {
          this.$refs.table.refresh()
        }
      }).finally(() => {
        this.loading = false
      })
    },
    handleDelete (item) {
      console.log(item)
      couponGoodsDelete({ barcode: item.barcode, coupon_id: this.$route.query.id }).then(res => {
        console.log(res)
        if (res.code === 1000) {
          this.$refs.table.refresh()
        }
      })
    }
  }
}
</script>
